<template>
  <canvas ref='canvas' :width="width" :height="height" :style="style"></canvas>
</template>

<script>
export default {
  name: 'TrendLineCanvas',
  props: {
    width: Number,
    height: Number,
    top: Number,
    left: Number,
    lineWidth: Number,
    start: Object,
    end: Object
  },
  data() {
    return {
      color: '#A9A9A9'
    }
  },
  mounted () {
    this.drawLine()
  },
  updated () {
    this.drawLine()
  },
  computed: {
    style () {
      return {
        top: this.top + 'px',
        left: this.left + 'px'
      }
    }
  },
  methods: {
    drawLine () {
      const ctx = this.$refs.canvas.getContext('2d')
      ctx.clearRect(0, 0, this.width, this.height)
      ctx.strokeStyle = this.color
      ctx.lineWidth = this.lineWidth
      ctx.moveTo(this.start.x, this.start.y)
      ctx.lineTo(this.end.x, this.end.y)
      ctx.closePath()
      ctx.stroke()
    }
  }
}
</script>

<style lang="scss" scoped>
canvas {
  position: absolute;
}
</style>
