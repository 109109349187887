<template>
    <div class="table_box" ref="table">
        <div v-for="(item, key) in list" :key="key" class="table_single width_flex" :class="key % 2 == 0 ? 'bk_white' : 'bk_grey'">
            <table cellpadding="0" cellspacing="0">
                <thead>
                    <tr>
                        <th rowspan="1" :colspan="config.length + special_config.length">{{item.title}}</th>
                    </tr>
                    <tr>
                        <th v-for="(c, ck) in config" :key="ck" rowspan="1" colspan="1">{{c['lable']}}</th>
                        <th v-if="(renderKey === item.title  || renderKey === 'all') && special_config.length > 0">
                            <div v-for="(c, ck) in special_config" :key="ck" rowspan="1" colspan="1">{{c['lable']}}</div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(it, k) in item.list" :key="k">
                        <td v-for="(c, ck) in config" :key="ck">
                            <div :class="render(it[c['key']] == c['lable'], ck, c['lable'], key)">
                                {{it[c['key']]}}
                            </div>
                        </td>
                        <td v-if="(renderKey === item.title || renderKey === 'all') && special_config.length > 0">
                            <div v-for="(c, ck) in special_config" :key="ck" :class="render(it[c['key']] == c['lable'], ck, c['lable'], key)">
                                {{it[c['key']]}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <trend-line-canvas v-for="obj in lineObjects"
          :key="obj.id"
          :width="obj.width"
          :height="obj.height"
          :start="obj.start"
          :end="obj.end"
          :color="obj.color"
          :line-width="obj.lineWidth"
          :top="obj.top"
          :left="obj.left"></trend-line-canvas>
    </div>
</template>

<script>
import randomString from '@plugins/random'
import TrendLineCanvas from '@components/pc/TrendLineCanvas'

export default {
    name: 'trendTable',
    components: {
        [TrendLineCanvas.name]: TrendLineCanvas
    },
    props: {
        renderKey: {
            default: ''
        },
        special_config: {
            default: () => {
                return []
            }
        },
        config: {
            default: () => {
                return [
                    { lable: '大', key: 'big' },
                    { lable: '小', key: 'small' },
                    { lable: '和', key: 'he' }
                ]
            }
        },
        list: {
            default: () => {
                return []
            }
        }
    },
    data () {
        return {
            lineObjects: []
        }
    },
    watch: {
        list: {
            handler () {
                this.lineObjects = []
                this.$nextTick(() => {
                    this.drawLine()
                })
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        render (bool, key, val, pkey) {
            if (bool) {
                if (val === '和') {
                    return 'hot_cc hot_color1 hot' + pkey
                } else {
                    const i = key % 2
                    if (i === 0) {
                        return 'hot_cc hot_color2 hot' + pkey
                    }
                    if (i === 1) {
                        return 'hot_cc hot_color3 hot' + pkey
                    }
                }
            }
            return ''
        },
    drawLine () {
      const arr = []
      const lineWidth = 1

      const tableRect = this.$refs.table.getBoundingClientRect()

      const hots = this.getHotElement()
      for (var rank = 0; rank < hots.length; rank++) {
        const group = hots[rank]
        if (typeof group === 'object') {
          var lastCenter = null

          for (let index = 0; index < group.length; index++) {
            const hot = group[index]
            const hotRect = hot.getBoundingClientRect()
            const x = hotRect.x - tableRect.x + hotRect.width / 2
            const y = hotRect.y - tableRect.y + hotRect.height / 2
            const center = {
              x: x,
              y: y
            }
            if (!lastCenter) {
              lastCenter = center
              continue
            }

            var obj = {
              lineWidth,
              color: '#1D8DDE',
              id: `${rank}-${index - 1}-${index}`
            }

            const canvasWidth = Math.abs(lastCenter.x - x)
            const canvasHeight = Math.abs(lastCenter.y - y)

            obj.width = canvasWidth
            obj.height = canvasHeight

            if (lastCenter.x > x) {
              obj.start = {
                x: canvasWidth,
                y: 0
              }
              obj.end = {
                x: 0,
                y: canvasHeight
              }

              obj.left = x
              obj.top = lastCenter.y
            } else if (lastCenter.x < x) {
              obj.start = {
                x: 0,
                y: 0
              }
              obj.end = {
                x: canvasWidth,
                y: canvasHeight
              }

              obj.left = lastCenter.x
              obj.top = lastCenter.y
            } else {
              obj.start = {
                x: lineWidth / 2,
                y: 0
              }
              obj.end = {
                x: lineWidth / 2,
                y: canvasHeight
              }

              obj.left = (lastCenter.x - lineWidth / 2)
              obj.top = lastCenter.y
              obj.width = lineWidth
            }

            arr.push(obj)
            lastCenter = center
          }
        }
      }
      this.lineObjects = arr
    },
    getHotElement () {
      var arr = []
      const len = this.list.length
      for (let i = 0; i < len; i++) {
        arr[i] = document.getElementsByClassName('hot' + i)
      }
      return arr
    }
    }
}
</script>

<style lang="scss" scoped>
    .table_box {
        width: 100%;
        border-left: thin solid #d6d6d6;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        .table_single {
            table {
                width: 100%;
                border-top: thin solid #d6d6d6;
            }
            th, td {
                border-right: thin solid #d6d6d6;
                border-bottom: thin solid #d6d6d6;
                text-align: center;
                box-sizing: border-box;
            }
            th {
                background-color: #f9f9f9;
                font-size: 14px;
                height: 36px;
                line-height: 36px;
                color: #A1A1A1;
            }
            td {
                font-size: 14px;
                height: 37px;
                line-height: 37px;
                color: #282828;
                >div {
                    display: inline-block;
                }
            }
            tbody tr:hover {
                background-color: rgba($color: #ffccaa, $alpha: 0.4);
            }
        }
    }
    .width_limit {
        flex-shrink: 0;
        width: 20%;
    }
    .width_flex {
        flex: 1;
    }
    .hot_cc {
        position: relative;
        z-index: 100;
        padding: 0 4px;
        min-width: 18px;
        height: 26px;
        color: #fff;
        line-height: 26px;
        box-sizing: content-box;
        font-size: 12px;
        text-align: center;
        border-radius: 50%;
    }
    .hot_color1 {
        background-color: #FFCC00;
    }
    .hot_color2 {
        background-color: #FA3E3E;
    }
    .hot_color3 {
        background-color: #07A6F8;
    }
    .bk_white {
        background-color: #fff;
    }
    .bk_grey {
       background-color: rgba(160, 160, 42, 0.1);
    }
</style>